import { makeStyles } from '@mui/styles'
import { Theme, colors } from '@mui/material'
import { Section } from 'components/organisms'
import { Hero, Hub, Subscription } from './components'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  shape: {
    background: "rgba(34, 181, 115, 0.1)",
    borderBottomRightRadius: '50%',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
}))

const Home = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.shape}>
        <Section className={classes.pagePaddingTop}>
          <Hero />
        </Section>
        <Section className={classes.sectionNoPaddingTop}>
          <Hub />
        </Section>
      </div>
      <Section>
        <Subscription />
      </Section>
      {/* <Section>
        <Partners data={[
          {
            logo: '/images/logos/slack.svg',
            name: 'Slack',
          },
          {
            logo: '/images/logos/mailchimp.svg',
            name: 'Mailchimp',
          },
          {
            logo: '/images/logos/dropbox.svg',
            name: 'Dropbox',
          },
          {
            logo: '/images/logos/google-drive.svg',
            name: 'Google Drive',
          },
          {
            logo: '/images/logos/atlassian.svg',
            name: 'Atlassian',
          },
        ]} />
      </Section> */}
    </div>
  )
}

export default Home
