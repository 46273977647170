import React from 'react'
import CountUp from 'react-countup'
import clsx from 'clsx'
import { Typography } from '@mui/material'
import { useInView } from 'react-intersection-observer'

/**
 * Component to display the Count Up Numbers
 *
 * @param {Object} props
 */
const CountUpNumber: React.FC<Props> = (props: Props) => {
  const {
    start,
    end,
    suffix,
    prefix,
    textColor,
    labelColor,
    className,
    wrapperProps,
    countWrapperProps,
    countNumberProps,
    labelProps,
    label,
    ...rest
  } = props
  
  const [ref, isInView] = useInView({
    triggerOnce: true
  })

  return (
    <div className={clsx('countup-number', className)} ref={ref} {...rest}>
        <div className="countup-number__wrapper" {...wrapperProps}>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            color={textColor || 'textPrimary'}
            className="countup-number__count-wrapper"
            {...countWrapperProps}
          >
            <CountUp
              delay={1}
              redraw={false}
              duration={1}
              end={isInView ? end : start!}
              start={start}
              suffix={suffix || ''}
              prefix={prefix || ''}
              className="countup-number__count"
              {...countNumberProps}
            />
          </Typography>
          <Typography
            variant="subtitle1"
            color={labelColor || 'textSecondary'}
            align="center"
            className="countup-number__label"
            {...labelProps}
          >
            {label}
          </Typography>
        </div>
    </div>
  )
}

CountUpNumber.defaultProps = {
  start: 0
}

interface Props {
  /**
   * External classes
   */
  className?: string,
  /**
   * The Suffix of the count up number
   */
  suffix?: string,
  /**
   * The Prefix of the count up number
   */
  prefix?: string,
  /**
   * Starting number
   */
  start?: number,
  /**
   * End number
   */
  end: number,
  /**
   * Text color
   */
  textColor?: string,
  /**
   * Label color
   */
  labelColor?: string,
  /**
   * Additional properties to pass to the wrapper div
   */
  wrapperProps?: object
  /**
   * Additional properties to pass to the count wrapper Typography component
   */
  countWrapperProps?: object
  /**
   * Additional properties to pass to the CountUp React component
   */
  countNumberProps?: object
  /**
   * Additional properties to pass to the label Typography component
   */
  labelProps?: object

  label: string
}

export default CountUpNumber
