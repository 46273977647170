import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Grid, Theme, Typography } from '@mui/material'
import { Image } from 'components/atoms'
import {
    SectionHeader,
    // CountUpNumber
} from 'components/molecules'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    placementGrid: {
        display: 'flex',
    },
    placementGridItemMiddle: {
        margin: `0 ${theme.spacing(3)}`,
    },
    coverImage: {
        boxShadow:
            '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
        },
    },
}))

const Features = (props: Props) => {
    const { className, ...rest } = props
    const classes = useStyles()

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Grid container spacing={4}>
                <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos="fade-up"
                >
                    <Image
                        src="/images/illustrations/marketing.jpg"
                        alt="..."
                        className={classes.coverImage}
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                    />
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos="fade-up"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SectionHeader
                                title={
                                    <span>
                                        開発だけではありません。
                                        <Typography component="span" variant="inherit" color="primary">
                                        Webサービスに必要なすべて
                                        </Typography>
                                        があります。
                                    </span>
                                }
                                subtitle="サービス保守、マーケティング、ライティングなど、Webサービス運営にはいろいろな業務が必要です。そのすべて、私たちにお任せください。"
                                align="left"
                                fadeUp
                                disableGutter
                                titleVariant="h3"
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <div className={classes.placementGrid}>
                                <div>
                                    <CountUpNumber
                                        end={20}
                                        label="Services"
                                        textColor="primary"
                                        suffix="+"
                                    />
                                </div>
                                <div className={classes.placementGridItemMiddle}>
                                    <CountUpNumber
                                        end={98}
                                        label="Satisfaction"
                                        textColor="primary"
                                        suffix="%"
                                    />
                                </div>
                                <div>
                                    <CountUpNumber
                                        end={5.0}
                                        label="Review Score"
                                        textColor="primary"
                                    />
                                </div>
                            </div>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

interface Props {
    /**
     * External classes
     */
    className?: string
}

export default Features
