import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { NoSsr } from '@mui/base'

const useStyles = makeStyles(() => ({
  root: {},
  extraSmall: {
    fontSize: 10,
  },
  small: {
    fontSize: 20,
  },
  medium: {
    fontSize: 30,
  },
  large: {
    fontSize: 40,
  },
}))

/**
 * Component to display the icon
 *
 * @param {Object} props
 */
const Icon = (props: Props) => {
  const { fontIconClass, size, fontIconColor, className, ...rest } = props

  const classes = useStyles()

  return (
    <NoSsr>
      <i
        className={clsx(
          'icon',
          classes.root,
          fontIconClass,
          classes[size],
          className,
        )}
        style={{ color: fontIconColor }}
        {...rest}
      />
    </NoSsr>
  )
}

Icon.defaultProps = {
  size: 'small',
}

interface Props {
  /**
   * External classes
   */
  className?: string,
  /**
   * The classes of the font icon
   */
  fontIconClass?: string,
  /**
   * Source set for the responsive images
   */
  size: 'extraSmall' | 'small' | 'medium' | 'large',
  /**
   * Color of the icon
   */
  fontIconColor?: string,
}

export default Icon
