import {
    Routes,
    Route,
} from "react-router-dom"
import { Home, NotFound } from "views"
const Pages: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default Pages
