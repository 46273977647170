import { createTheme, responsiveFontSizes } from '@mui/material'
import palette from './palette'


const theme = responsiveFontSizes(
    createTheme({
        palette,
        typography: {
            fontFamily: 'Lato',
        },
        zIndex: {
            appBar: 1200,
            drawer: 1100,
        },
    }, {
        layout: {
            contentWidth: 1140,
        },
        overrides: {
            MuiButton: {
                containedSecondary: {
                    color: 'white',
                },
            },
        },
    }
    ,),
)

export default theme
