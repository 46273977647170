import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  dBlock: {
    display: 'block',
  },
}))

/**
 * Component to display the images
 *
 * @param {Object} props
 */
export const Image = (props: Props) => {
  const { src, srcSet, alt, lazy, lazyProps, className, ...rest } = props

  const classes = useStyles()
  if (lazy) {
    return (
      <LazyLoadImage
        className={clsx('image', classes.root, classes.dBlock, className)}
        alt={alt}
        src={src}
        srcSet={srcSet}
        effect="opacity"
        {...lazyProps}
        {...rest}
      />
    )
  }

  return (
    <img
      className={clsx('image', classes.root, className)}
      alt={alt}
      src={src}
      srcSet={srcSet}
      {...rest}
    />
  )
}

Image.defaultProps = {
  alt: '...',
  lazy: true,
  lazyProps: {
    width: 'auto',
    height: 'auto',
  },
}

interface Props {
  /**
   * External classes
   */
  className?: string,
  /**
   * Source of the image
   */
  src: string,
  /**
   * Source set for the responsive images
   */
  srcSet?: string,
  /**
   * Image title
   */
  alt?: string,
  /**
   * Should lazy load the image
   */
  lazy?: boolean,

  lazyProps?: {
    width: "auto",
    height: "auto"
  }
}
