import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { List, ListItem, Theme } from '@mui/material'
// import FacebookIcon from '@mui/icons-material/Facebook'
// import TwitterIcon from '@mui/icons-material/Twitter'
// import InstagramIcon from '@mui/icons-material/Instagram'
// import PinterestIcon from '@mui/icons-material/Pinterest'

import { Image } from '../../../components/atoms'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: 'rgba(255, 255, 255, .7)',
        padding: theme.spacing(3, 0),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6, 0),
        },
        background: theme.palette.primary.main,
    },
    footerContainer: {
        maxWidth: 1140,
        width: '100%',
        margin: '0 auto',
        padding: theme.spacing(0, 2),
    },
    logoContainerItem: {
        paddingTop: 0,
    },
    logoContainer: {
        width: 120,
        height: 45,
        paddingBottom: 9,
    },
    logoImage: {
        width: 'auto',
        height: '100%',
        opacity: 0.7,
    },
    socialIcon: {
        padding: 0,
        marginRight: theme.spacing(1),
        '&:hover': {
            background: 'transparent',
        },
        '&:last-child': {
            marginRight: 0,
        },
    },
    icon: {
        color: 'rgba(255,255,255,.9)',
        fontSize: 24,
    },
    copyright: {
        width: '100%',
        textAlign: 'center',
    }
}))

interface Props {
    className?: string
}

export const Footer: React.FC<Props> = (props: Props) => {
    const { className, ...rest } = props

    const classes = useStyles()

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.footerContainer}>
                <List disablePadding>
                    <ListItem disableGutters className={classes.logoContainerItem}>
                        <div className={classes.logoContainer}>
                            <a href="/" title="LilySoft">
                                <Image
                                    className={classes.logoImage}
                                    src="/images/logos/logo-landscape-white.svg"
                                    alt="LilySoft"
                                    lazy={false}
                                />
                            </a> 
                        </div>
                        リリーソフト株式会社
                    </ListItem>
                    
                    <div className={classes.copyright}><span>
                    Copyright © {new Date().getFullYear()} LilySoft. All rights reserved.
                    </span>
                    </div>
                    {/* <ListItem disableGutters>
                        <IconButton className={classes.socialIcon}>
                            <FacebookIcon className={classes.icon} />
                        </IconButton>
                        <IconButton className={classes.socialIcon}>
                            <InstagramIcon className={classes.icon} />
                        </IconButton>
                        <IconButton className={classes.socialIcon}>
                            <TwitterIcon className={classes.icon} />
                        </IconButton>
                        <IconButton className={classes.socialIcon}>
                            <PinterestIcon className={classes.icon} />
                        </IconButton>
                    </ListItem> */}
                </List>
            </div>
        </div>
    )
}
