import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Button, Theme } from '@mui/material'
import { SectionHeader } from 'components/molecules'

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    image: {
        boxShadow:
            '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
        },
    },
    lastGrid: {
        [theme.breakpoints.up('sm')]: {
            marginTop: '40%',
        },
    },
}))

const Subscription = (props: Props) => {
    const { className, ...rest } = props
    const classes = useStyles()

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <SectionHeader
                label="お問い合わせ"
                title="お申し込みはこちらから"
                subtitle="※現在新規お申し込みは停止しております。募集再開をお待ちください。"
                align="center"
                ctaGroup={[
                    <Button variant="contained" color="primary" size="large" disabled >
                        お申し込み
                    </Button>,
                    <Button variant="outlined" color="primary" size="large" disabled>
                        資料請求
                    </Button>,
                ]}
                data-aos="fade-up"
            />
        </div>
    )
}

interface Props {
    /**
     * External classes
     */
    className?: string
}

export default Subscription
