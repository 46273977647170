import { CSSProperties } from 'react'
import { makeStyles } from '@mui/styles'
import { Button, Theme } from '@mui/material'
import { SectionHeader } from 'components/molecules'
import { Section } from 'components/organisms'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${(theme.mixins.toolbar['@media (min-width:600px)'] as CSSProperties).minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}))

const NotFound = () => {
  const classes = useStyles()

  const handleClick = () => {
    window.history.back()
  }

  return (
    <div className={classes.root}>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            title="404 Not Found"
            subtitle={
              <span>
                お探しのページは見つかりませんでした。
              </span>
            }
            titleProps={{
              variant: 'h3',
            }}
            labelProps={{
              color: 'secondary',
              className: classes.label,
              variant: 'h5',
            }}
            ctaGroup={[
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                戻る
              </Button>,
            ]}
            disableGutter
          />
        </div>
      </Section>
    </div>
  )
}

export default NotFound
