import clsx from 'clsx'
import { makeStyles, useTheme } from '@mui/styles'
import { useMediaQuery, Grid, Typography, Theme } from '@mui/material'
import { Image } from 'components/atoms'
import { SectionHeader } from 'components/molecules'

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    image: {
        boxShadow:
            '25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2)',
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
        },
    },
}))

const Hero = (props: Props) => {
    const { className, ...rest } = props
    const classes = useStyles()

    const theme: Theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    })

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Grid
                container
                justifyContent="space-between"
                spacing={4}
                direction={isMd ? 'row' : 'column-reverse'}
            >
                <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <SectionHeader
                        title={
                            <span>
                                <Typography component="span" variant="inherit" color="primary">
                                LilySoft
                                </Typography>
                                はWeb開発のプロフェッショナルです。
                            </span>
                        }
                        subtitle="私たちはWebサービス/Webアプリ/ネイティブアプリの開発を行なっています。もちろん、プロダクトの開発には最新の技術を使用しています。"
                        // ctaGroup={[
                        //     <Button variant="contained" color="primary" size="large">
                        //         お問い合わせ
                        //     </Button>,
                        //     <Button variant="outlined" color="primary" size="large">
                        //         詳しく見る
                        //     </Button>,
                        // ]}
                        align="left"
                        disableGutter
                        titleVariant="h3"
                    />
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    xs={12}
                    md={6}
                    data-aos={'fade-up'}
                >
                    <Image
                        src="/images/illustrations/development.jpg"
                        alt="LilySoftはWeb開発のプロフェッショナルです。"
                        className={classes.image}
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                    />
                </Grid>
            </Grid>
        </div>
    )
}

interface Props {
    /**
     * External classes
     */
    className?: string
}

export default Hero
