import {
    Toolbar,
    // Hidden,
    // List,
    // ListItem,
    // Typography,
    // IconButton,
    // Button,
    Theme,
} from '@mui/material'
// import MenuIcon from '@mui/icons-material/Menu'
import { createStyles, makeStyles } from '@mui/styles'
import { Image } from '../../../components/atoms'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    flexGrow: {
        flexGrow: 1,
    },
    navigationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    toolbar: {
        width: '100%',
        margin: '0 auto',
        maxWidth: 1140,
        padding: theme.spacing(0, 2),
    },
    listItem: {
        cursor: 'pointer',
        paddingTop: 0,
        paddingBottom: 0,
    },
    listItemText: {
        flex: '0 0 auto',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
    },
    listItemButton: {
        whiteSpace: 'nowrap',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    logoContainer: {
        width: 150,
        height: 50,
        [theme.breakpoints.up('md')]: {
            width: 180,
            height: 60,
        },
        verticalAlign: 'middle',
    },
    logoImage: {
        width: '70%',
    },
}))

interface Props {
    onSidebarOpen: () => void
}

export const Topbar = (props: Props) => {
    const { onSidebarOpen, ...rest } = props

    const classes = useStyles()

    return (
        <Toolbar disableGutters className={classes.toolbar} {...rest}>
            <div className={classes.logoContainer}>
                <a href="/" title="thefront">
                    <Image
                        className={classes.logoImage}
                        src="/images/logos/logo-landscape.svg"
                        alt="LilySoft"
                        lazy={false}
                    />
                </a>
            </div>
            {/* <div className={classes.flexGrow} />
            <Hidden mdDown>
                <List className={classes.navigationContainer}>
                    <ListItem className={classes.listItem}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classes.listItemText}
                            component="a"
                            href="/"
                        >
                            Home
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classes.listItemText}
                            component="a"
                            href="/signup-simple"
                        >
                            Sign up
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classes.listItemText}
                            component="a"
                            href="/not-found"
                        >
                            Error Page
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            component="a"
                            target="blank"
                            href="/"
                            className={classes.listItemButton}
                        >
                            お問い合わせ
                        </Button>
                    </ListItem>
                </List>
            </Hidden>
            <Hidden mdUp>
                <IconButton
                    className={classes.iconButton}
                    onClick={onSidebarOpen}
                    aria-label="Menu"
                >
                    <MenuIcon />
                </IconButton>
            </Hidden> */}
        </Toolbar>
    )
}
