import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Drawer } from '@mui/material'

import { SidebarNav } from './SidebarNav'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: '100%',
    maxWidth: 400,
  },
  root: {
    height: '100%',
    padding: theme.spacing(1),
  },
  nav: {
    marginBottom: theme.spacing(1),
  },
}))

export const Sidebar = (props: Props) => {
  const { open, variant, onClose, className, ...rest } = props

  const classes = useStyles()

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} onClose={onClose} />
      </div>
    </Drawer>
  )
}

interface Props {
  className?: string
  onClose?: () => void
  open: boolean
  variant: 'permanent' | 'persistent' | 'temporary'
}
