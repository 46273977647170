import { useState } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@mui/styles'
import { useMediaQuery, Divider, Theme } from '@mui/material'
import { Topbar, Footer, Sidebar } from './components'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
}))

interface Props {
  children?: React.ReactNode
}


export const Main = (props: Props) => {
  const { children } = props

  const classes = useStyles()

  const theme: Theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const open = isMd ? false : openSidebar

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
      />
      <main>
        <Divider />
        {children}
      </main>
      <Footer />
    </div>
  )
}
