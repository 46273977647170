import clsx from 'clsx'
import { makeStyles, useTheme } from '@mui/styles'
import { useMediaQuery, Grid, Theme } from '@mui/material'
import { Image } from 'components/atoms'
import { SectionHeader } from 'components/molecules'
import { CardBase } from 'components/organisms'

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    logo: {
        maxWidth: 50,
    },
}))

const Partners = (props: Props) => {
    const { data, className, ...rest } = props
    const classes = useStyles()

    const theme: Theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    })

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Grid container spacing={isMd ? 4 : 2}>
                <Grid item xs={12} md={6} data-aos="fade-up">
                    <SectionHeader
                        title="We love to explore new ways to engage with brands and reach"
                        subtitle="Our mission is to help you to grow your design skills, meet and connect with professional dsigners who share your passions."
                        align="left"
                        label="100+ Integrations"
                        disableGutter
                    />
                </Grid>
                <Grid item xs={12} md={6} data-aos="fade-up">
                    <Grid container spacing={2}>
                        {data.map((item: Item, index: number) => (
                            <Grid item xs={4} key={index}>
                                <CardBase withShadow liftUp>
                                    <Image
                                        src={item.logo}
                                        alt={item.name}
                                        className={classes.logo}
                                        lazy={false}
                                    />
                                </CardBase>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

interface Item {
    logo: string
    name: string
}

interface Props {
    /**
     * External classes
     */
    className?: string,
    /**
     * data to be rendered
     */
    data: Item[],
}

export default Partners
